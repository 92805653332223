<template>
  <div class="home">







    <div>

      <div>
        <el-carousel :height="height">
          <el-carousel-item v-for="item in list" :key="item.contentId">
            <img :src="item.titlePhoto" class="img1" :alt="item.title" width="100%" ref="imgs" @load="imgLoad">
          </el-carousel-item>

        </el-carousel>

      </div>
    </div>

    <div class="banner x_banner">

      <div class="swiper-container swiper-container-horizontal">
        <div class="swiper-wrapper " style="transition-duration: 500ms;">
          <div class="swiper-slide swiper-slide-duplicate" sid="" data-swiper-slide-index="3"><img
              src="@/assets/images/banner4.png" class="img2"></div>
          <div class="swiper-slide swiper-slide-prev" sid="" data-swiper-slide-index="0"><img
              src="@/assets/images/banner1.jpg" class="img2"></div>
          <div class="swiper-slide swiper-slide-active" sid="" data-swiper-slide-index="1"><img
              src="@/assets/images/banner2.png" class="img2"></div>
          <div class="swiper-slide swiper-slide-next" sid="" data-swiper-slide-index="2"><img
              src="@/assets/images/banner3.jpg" class="img2"></div>
          <div class="swiper-slide" sid="" data-swiper-slide-index="3"><img src="@/assets/images/banner4.png"
              class="img2"></div>
          <div class="swiper-slide swiper-slide-duplicate" sid="#" data-swiper-slide-index="0"><img
              src="@/assets/images/banner1.jpg" class="img2"></div>
        </div>
        <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"><span
            class="swiper-pagination-bullet"></span><span
            class="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span
            class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span></div>
      </div>
    </div>


    <div class="main">
      <div class="shuju">
        <div>
          <p>
            20<span>
              年
            </span>
          </p>
          <div>
            20年开发与积累提供专业化的期刊数字出版平台服务
          </div>
        </div>
        <div>
          <p>
            1000<span>
              种
            </span>
          </p>
          <div>
            超过1000种国内一流期刊使用公司产品或服务
          </div>
        </div>
        <div>
          <p>
            10<span>
              万
            </span>
          </p>
          <div>
            每年有超过10万篇文章进行在线发布
          </div>
        </div>
        <div>
          <p>
            50<span>
              万
            </span>
          </p>
          <div>
            每年有超过50万人次审稿人在线完成审稿
          </div>
        </div>
        <div>
          <p>
            1000<span>
              万
            </span>
          </p>
          <div>
            每年有超过1000万人次读者通过系统获取最新学术文献
          </div>
        </div>
      </div>
      <div class="newMedia">
        <div class="inner">
          <div class="baseTitle">
            <h3 class="title1 t-upp f35">期刊新媒体<a name="qkxmt"></a></h3>
            <div class="title2 f28 co-3c">New Media of Academic Journals</div>
          </div>
          <ul class="clear">
            <li>
              <a @click="jump('/home/list', {
          Channelid: 2380,
          id: 2375
        })">
                <div class="icon"><img alt="学术人生" src="@/assets/images/xses1.png" class="img1"><img alt="学术人生"
                    src="@/assets/images/xses2.png" class="img2"></div>
              </a>
              <h4 class="title f20">学术人生</h4>

              <div class="text">
                <p>学术期刊编委审稿专家学术成就：视频，课件，著作，直播，文献个性定制电子文集。官网，微信，app一库三网一键发布。</p>
              </div>

            </li>
            <li>
              <a @click="jump('/home/list', {
          Channelid: 2381,
          id: 2375
        })">
                <div class="icon"><img alt="知识分享" src="@/assets/images/zsfx1.png" class="img1"><img alt="知识分享"
                    src="@/assets/images/zsfx2.png" class="img2"></div>
              </a>
              <h4 class="title f20">知识分享</h4>
              <div class="text">专业学科学术期刊权威学术资源激活，实现知识付费有偿服务。</div>
            </li>
            <li>
              <a @click="jump('/home/list', {
          Channelid: 2383,
          id: 2375
        })">
                <div class="icon"><img alt="学术会议" src="@/assets/images/xshy1.png" class="img1"><img alt="学术会议"
                    src="@/assets/images/xshy2.png" class="img2"></div>
              </a>
              <h4 class="title f20">学术会议</h4>
              <div class="text">专业学科领域学术会议一库三网管理系统，会议全程智能管理，移动办公。</div>
            </li>
            <li>
              <a @click="jump('/home/list', {
          Channelid: 2382,
          id: 2375
        })">
                <div class="icon"><img alt="视频直播" src="@/assets/images/spzb1.png" class="img1"><img alt="视频直播"
                    src="@/assets/images/spzb2.png" class="img2"></div>
              </a>
              <h4 class="title f20">视频直播</h4>
              <div class="text">专业学术课件视频，直播，流量递增，提升学术期刊业界影响力。</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="clear"></div>
      <div class="hu bgf">
        <div class="baseTitle">
          <h3 class="title1 t-upp f35">智能采编<a name="zncb"></a></h3>
          <div class="title2 f28 co-3c">Intelligent Editing</div>
        </div>
        <div class="bot">
          <div class="inner">
            <div class="tab" v-if="curr == 1">
              <div class="baseTitle">
                <h3 class="title1 f25">作者投稿系统</h3>
                <p>作者通过注册投稿，支持多种文件格式投稿。查看稿件的当前状态、在线返修稿件和撤销稿件。</p>
              </div>
            </div>
            <div class="tab" v-if="curr == 2">
              <div class="baseTitle">
                <h3 class="title1 f25">专家审稿系统</h3>
                <p>外审专家可通过自己的邮箱自动登录，可在线填写审稿意见单，提供审稿模板，可自行选择使用。</p>
              </div>
            </div>
            <div class="tab" v-if="curr == 3">
              <div class="baseTitle">
                <h3 class="title1 f25">编辑流程自定义</h3>
                <p>各个编辑部可以沿用之前的工作习惯，自行设置自己喜欢的稿件处理流程，及流程权限。</p>
              </div>
            </div>
            <div class="tab" v-if="curr == 4">
              <div class="baseTitle">
                <h3 class="title1 f25">学术不端查询</h3>
                <p>无缝对接万方学术不端检测系统，编辑点击新稿件即可生成学术不端检测报告。</p>
              </div>
            </div>
            <div class="tab" v-if="curr == 5">
              <div class="baseTitle">
                <h3 class="title1 f25">参考文献校对</h3>
                <p>无缝对接万方参考文献校对系统，文献校对数据一目了然。</p>
              </div>
            </div>
            <div class="tab" v-if="curr == 6">
              <div class="baseTitle">
                <h3 class="title1 f25">过刊三网发布系统</h3>
                <p>过刊文献一键发布到期刊官方网站，同时在微信公众号（小程序）期刊app同时发布。</p>
              </div>
            </div>
            <div class="tab" v-if="curr == 7">
              <div class="baseTitle">
                <h3 class="title1 f25">APP移动采编系统</h3>
                <p>国内首家可在移动终端处理稿件流程的采编系统。</p>
              </div>
            </div>
            <div class="tab" v-if="curr == 8">
              <div class="baseTitle">
                <h3 class="title1 f25">期刊集群文库</h3>
                <p>为国内很多行业或机构的数百或数十家期刊搭建文库，打通不同专业和地域的限制，方便相关专业的作者、编辑人员及科研工作者使用。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="huUl">
          <div class="inner">
            <ul class="clear">
              <li @mousemove="change(1)">
                <a>
                  <div class="pic"><img alt="作者投稿系统" src="@/assets/images/zncb1.png" class="img1">
                  </div>
                  <p>作者投稿系统</p>
                </a>
              </li>
              <li @mousemove="change(2)">
                <a>
                  <div class="pic"><img alt="专家审稿系统" src="@/assets/images/zncb2.png" class="img1">
                  </div>
                  <p>专家审稿系统</p>
                </a>
              </li>
              <li @mousemove="change(3)">
                <a>
                  <div class="pic"><img alt="编辑流程自定义" src="@/assets/images/zncb3.png" class="img1">
                  </div>
                  <p>编辑流程自定义</p>
                </a>
              </li>
              <li @mousemove="change(4)">
                <a>
                  <div class="pic"><img alt="学术不端查询" src="@/assets/images/zncb4.png" class="img1">
                  </div>
                  <p>学术不端查询</p>
                </a>
              </li>
              <li @mousemove="change(5)">
                <a>
                  <div class="pic"><img alt="参考文献校对" src="@/assets/images/zncb5.png" class="img1">
                  </div>
                  <p>参考文献校对</p>
                </a>
              </li>
              <li @mousemove="change(6)">
                <a>
                  <div class="pic"><img alt="过刊三网发布系统" src="@/assets/images/zncb6.png" class="img1">
                  </div>
                  <p>过刊三网发布系统</p>
                </a>
              </li>
              <li @mousemove="change(7)">
                <a>
                  <div class="pic"><img alt="APP移动采编系统" src="@/assets/images/zncb7.png" class="img1">
                  </div>
                  <p>APP移动采编系统</p>
                </a>
              </li>
              <li @mousemove="change(8)">
                <a>
                  <div class="pic"><img alt="期刊集群文库" src="@/assets/images/zncb8.png" class="img1">
                  </div>
                  <p>期刊集群文库</p>
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <div class="gong">
      <div class="inner">
        <div class="baseTitle">
          <h3 class="title1 t-upp f35">价值贡献<a name="jzgx"></a></h3>
          <div class="title2 f28 co-3c">Contribution value</div>
          <p></p>
        </div>
      </div>
      <div class="bot clear">
        <ul class="inner">
          <li>
            <div class="box1 hover">
              <div class="pic" style="height: auto;"><img src="@/assets/images/mbg-1.jpg"></div>
              <div class="con">
                <div class="icon"><img src="@/assets/images/gongIcon_03.png"></div>
                <div class="title">作者收益</div>
                <div class="text">投稿作者定期会收到投稿期刊最新学术动态和优秀文章。</div>
                <div class="btn"><a @click="jump('/home/show', {
          Channelid: 2385,
          id: 2384
        })"><img src="@/assets/images/btn.png" title="查看详细"></a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="box2 hover">
              <div class="pic" style="height: auto;"><img src="@/assets/images/mbg-2.jpg"></div>
              <div class="con">
                <div class="icon"><img src="@/assets/images/gongIcon_06.png"></div>
                <div class="title">专家收益</div>
                <div class="text">通过新媒体平台让专家的学术成果得到更好的传播。</div>
                <div class="btn"><a @click="jump('/home/show', {
          Channelid: 2387,
          id: 2384
        })"><img src="@/assets/images/btn.png" title="查看详细"></a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="box3 hover">
              <div class="pic" style="height: auto;"><img src="@/assets/images/mbg-3.jpg"></div>
              <div class="con">
                <div class="icon"><img src="@/assets/images/gongIcon_11.png"></div>
                <div class="title">期刊收益</div>
                <div class="text">新媒体为期刊快速提升影响力，行业知名度。</div>
                <div class="btn"><a @click="jump('/home/show', {
          Channelid: 2386,
          id: 2384
        })"><img src="@/assets/images/btn.png" title="查看详细"></a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="box4 hover">
              <div class="pic" style="height: auto;"><img src="@/assets/images/mbg-4.jpg"></div>
              <div class="con">
                <div class="icon"><img src="@/assets/images/gongIcon_14.png"></div>
                <div class="title">行业收益</div>
                <div class="text">科技创新知识，让业界通过多平台更加便捷，高效传播。</div>
                <div class="btn"><a @click="jump('/home/show', {
          Channelid: 2388,
          id: 2384
        })"><img src="@/assets/images/btn.png" title="查看详细"></a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="box5 hover">
              <div class="pic" style="height: auto;"><img src="@/assets/images/mbg-5.jpg"></div>
              <div class="con">
                <div class="icon"><img src="@/assets/images/gongIcon_066.png"></div>
                <div class="title">社会贡献</div>
                <div class="text">避免一稿多投，杜绝学术不端。</div>
                <div class="btn"><a @click="jump('/home/show', {
          Channelid: 2389,
          id: 2384
        })"><img src="@/assets/images/btn.png" title="查看详细"></a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>


    </div>

    <div class="gong" style="padding-bottom:30px;">
      <div class="inner">
        <div class="baseTitle">
          <h3 class="title1 t-upp f35">解决方案<a name="jzgx"></a></h3>
          <div class="title2 f28 co-3c">Solution</div>
          <p></p>
        </div>
      </div>
      <div style='display: flex;width: 1200px;margin:0 auto;'>
        <div style="flex:1;margin-right:40px;padding-top:40px;font-size: 18px;">
          北京博渊星辰网络科技有限公司是从事学术期刊信息化研发、推广建设及整体解决方案的专业公司。公司根据中国学术期刊的现状，开发了整套新媒体服务智能采编系统和各种新媒体内容的应用场景，让学术期刊可以充分利用期刊自身资源为社会和受众提供各项服务，使学术期刊的行业影响力得到快速提升。还通过为国内不同行业或机构的期刊集群搭建文库，打通不同专业和地域的限制，方便相关专业的作者、编辑人员及科研工作者使用，促进了互联网时代信息技术的发展。
        </div>
        <div>
          <img src="@/assets/images/jjfa.png" style="" />
        </div>
      </div>


    </div>


    <div class="partner">
      <div class="inner">
        <div class="baseTitle">
          <h3 class="title1 t-upp f35">合作伙伴<a name="hzhb"></a></h3>
          <div class="title2 f28 co-3c">Cooperative Partner</div>
          <p></p>
        </div>
        <ul class="hezuo_list" style="display: flex;flex-wrap: wrap;">
          <li>
            <a href="http://www.cnki.net" title="中国知网" target="_blank">
              <img src="@/assets/images/yqlj/1.png" alt="中国知网">
            </a>
          </li>
          <li>
            <a href="http://med.wanfangdata.com.cn/" title="万方医学网" target="_blank">
              <img src="@/assets/images/yqlj/2.png" alt="万方医学网">
            </a>
          </li>
          <li>
            <a href="http://www.baidu.com" title="百度" target="_blank">
              <img src="@/assets/images/yqlj/3.png" alt="百度">
            </a>
          </li>


          <li>
            <a href="http://www.cmda.net/" title="中国医师协会" target="_blank">
              <img src="@/assets/images/yqlj/4.png" alt="中国医师协会">
            </a>
          </li>
          <li>
            <a href="http://www.cha.org.cn/" title="中国医院协会" target="_blank">
              <img src="@/assets/images/yqlj/5.png" alt="中国医院协会">
            </a>
          </li>
          <li>
            <a href="http://www.cessp.org.cn/" title="中国科技期刊协会" target="_blank">
              <img src="@/assets/images/yqlj/6.png" alt="中国科技期刊协会">
            </a>
          </li>
          <li>
            <a href="http://www.sysu.edu.cn" title="中山大学" target="_blank">
              <img src="@/assets/images/yqlj/7.png" alt="中山大学">
            </a>
          </li>
          <li>
            <a href="http://www.whu.edu.cn/" title="武汉大学" target="_blank">
              <img src="@/assets/images/yqlj/8.png" alt="武汉大学">
            </a>
          </li>
          <li>
            <a href="http://www.hust.edu.cn/" title="华中科技大学" target="_blank">
              <img src="@/assets/images/yqlj/9.png" alt="华中科技大学">
            </a>
          </li>
          <li>
            <a href="https://www.pku.edu.cn/" title="北京大学" target="_blank">
              <img src="@/assets/images/yqlj/10.png" alt="北京大学">
            </a>
          </li>



          <li>
            <a href="http://www.cass.cn/" title="中国社会科学院" target="_blank">
              <img src="@/assets/images/yqlj/11.png" alt="中国社会科学院">
            </a>
          </li>
          <li>
            <a href="http://www.nhc.gov.cn/" title="中华人民共和国国家卫生健康委员会" target="_blank">
              <img src="@/assets/images/yqlj/12.png" alt="中华人民共和国国家卫生健康委员会">
            </a>
          </li>


        </ul>
      </div>
    </div>

    <div style="color:#fff;padding-top:60px;" class="gywm">
      <div style="position:relative;height:70px;text-align: center;">
        <p class="title2 f28 co-3c" style="color:#bbbbbb;opacity: 0.3;font-size:40px;">
          About Us
        </p>
        <h3 style="text-align: center;position: absolute;left:50%;transform: translateX(-50%);top:-10px;font-size:30px;"
          class=" t-upp f35">
          关于我们

        </h3>
      </div>

      <div style="width:1200px;margin:0 auto 30px; 
font-family: PingFang SC;
font-weight: 400;
font-size: 18px;
color: #FFFFFF;
line-height: 32px;">
        北京博渊星辰网络科技有限公司是从事学术期刊信息化研发、推广建设及整体解决方案的专业公司。公司根据中国学术期刊的现状,开发了整套新媒体服务智能采编系统和各种新媒体内容的应用场景，让学术期刊可以充分利用期刊自身资源为社会和受众提供各项服务，使学术期刊的行业影响力得到快速提升。公司业务还包括为国内很多行业或机构的期刊集群搭建文库，打通不同专业和地域的限制，方便相关专业的作者、编辑人员及科研工作者使用。
      </div>
      <div style="display: flex;justify-content: space-around;width: 1200px;margin:0 auto;">
        <div style="text-align:center;">
          <img src="@/assets/images/dw.png" />
          <div style="font-size:20px;font-weight: bold;margin-top:10px;">
            定位
          </div>
          <p>
            学术期刊智能数字化新媒体解决服务商
          </p>
        </div>
        <div style="text-align:center;">
          <img src="@/assets/images/sm.png" />
          <div style="font-size:20px;font-weight: bold;margin-top:10px;">
            使命
          </div>
          <p>
            服务学术期刊，助力科技创新
          </p>
        </div>
        <div style="text-align:center;">
          <img src="@/assets/images/yj.png" />
          <div style="font-size:20px;font-weight: bold;margin-top:10px;">
            愿景
          </div>
          <p>
            为知识传播开创新模式
          </p>
        </div>
      </div>
    </div>


    <div class="partner">
      <div class="inner">
        <div class="baseTitle">
          <h3 class="title1 t-upp f35">联系我们<a name="hzhb"></a></h3>
          <div class="title2 f28 co-3c">Contact Us</div>
          <p></p>
        </div>
        <div class="lxwm">
          <div>
            <div>
              <img src="@/assets/images/dz.png" />
            </div>
            <p>
              公司地址
            </p>
            <li>

            </li>
            <div>
              北京市海淀区莲花池东路小马厂6号华天大厦824室
            </div>
          </div>
          <div style="margin:0 22px;">
            <div>
              <img src="@/assets/images/dh.png" />
            </div>
            <p>
              客服电话
            </p>
            <li>

            </li>
            <div>
              010-63361626
            </div>
          </div>
          <div>
            <div>
              <img src="@/assets/images/yx.png" />
            </div>
            <p>
              电子邮箱
            </p>
            <li>

            </li>
            <div>
              boyuanxingchen@163.com
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="scroll-top" style="display: none;"><img src="@/assets/images/scroll-1.png" /></div>
    <div class="bdshare-slide-button-box bdshare-slide-style-r1"
      style="top: 83px; width: 0px; z-index: 99999; right: 0px;" data-bd-bind="1554127579105"><a
        href="http://bosihw.com/#" onclick="return false;" class="bdshare-slide-button" style="left: -24px;"></a>
      <div class="bdshare-slide-list-box" style="width: 0px; display: none;">
        <div class="bdshare-slide-top">分享到</div>
        <div class="bdshare-slide-list">
          <ul class="bdshare-slide-list-ul" style="width: 116px;"></ul>
        </div>
        <div class="bdshare-slide-bottom" style="width: 116px;"><a href="http://zhujianyun.com/#"
            onclick="return false;" class="slide-more" data-cmd="more">更多...</a></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ResizeObserverPolyfill from 'resize-observer-polyfill';
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      curr: 1,
      list: [],
      height: '0'
    }
  },
  methods: {
    imgLoad() {
      this.height = this.$refs.imgs[0].height + 'px'
      // let target = this.$refs.imgs
      // let fn = new ResizeObserverPolyfill((entries) => {
      //   entries.forEach(entry => {
      //     // entry.contentRect属性包含了DOM元素的新尺寸信息
      //     console.log(Math.floor(entry.contentRect.height), '看看')
      //     this.$nextTick(_=>{
      //       this.height = Math.floor(entry.contentRect.height) + 'px'
      //     })

      //     // 这里可以根据需求执行相关操作
      //   });
      // })
      // fn.observe(target[0])
    },
    change(index) {
      this.curr = index
    },
    jump(path, query) {
      this.$router.push({
        path,
        query
      })

    },
    init() {
      this.$axios.post('/SiteWebApi/GetHomePageBlockInfo', {
        channelId: 3990,
        curr: 1,
        dataShowType: 1,
        dataSourceType: 1,
        jChannelID: 0,
        journalId: 201904080001,
        limit: 10,
        pChannelId: 0,
      }).then(res => {
        this.list = res.data.data
      })
    }
  },
  watch: {
    '$route'() {
      var targetElement = document.getElementById(targetElementId);
      if (targetElement) {
        targetElement.scrollIntoView();

        // 若想添加平滑滚动效果，则可以使用第三方库如jQuery Smooth Scroll等
        /*
        $(document).ready(function() {
            $('html, body').animate({
                scrollTop: $('#' + targetElementId).offset().top
            }, 500);
        });
        */
      } else {
        console.error('未找到与锚点对应的元素');
      }
      console.log('123123')
    }
  },
  mounted() {


  },
  created() {
    this.init()
  }
}
</script>
<style lang="less" scoped>
.gywm {
  padding-bottom: 40px;
  background-image: url('../assets/images/bg.png');
  background-size: 100% 100%;
}

.lxwm {
  display: flex;
  margin: 0 auto;
  width: 1200px;
  justify-content: space-between;

  >div {
    padding-bottom: 53px;
    padding-top: 33px;
    flex: 1;
    text-align: center;
    box-shadow: 0px 1px 13px 0px rgba(130, 130, 130, 0.25);
    border: 1px solid #E5E5E5;

    >div:first-of-type {
      height: 60px;
    }

    >p {
      margin-top: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #666666;
    }

    li {
      margin: 10px auto 40px;
      list-style: none;
      width: 97px;
      height: 1px;
      background: #828282;
      opacity: 0.2;
    }
  }
}

a {
  cursor: pointer;
}

.shuju {
  height: 195px;
  background: url('../assets/images/shuju-bj.png')no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;

  >div {
    margin-right: 140px;

    >p {
      height: 75px;
      font-family: STSongti-TC, STSongti-TC;
      font-weight: bold;
      font-size: 54px;
      color: #FFFFFF;
      line-height: 75px;
      text-align: center;
      font-style: normal;

      span {
        font-size: 14px;
      }
    }

    >div {
      width: 215px;
      font-family: STSongti-TC, STSongti-TC;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 20px;
      text-align: center;
      font-style: normal;
    }
  }

  >div:last-of-type {
    margin: 0;
  }
}
</style>
