var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"top",staticStyle:{"display":"flex","padding-bottom":"2px"}},[_c('div',{staticClass:"inner clear",staticStyle:{"display":"flex","align-items":"flex-end","width":"100%","max-width":"100%","justify-content":"center"}},[_c('a',{staticClass:"logo fl",staticStyle:{"margin-right":"60px"},on:{"click":function($event){return _vm.jump('/Home/Index', {

          })}}},[_c('img',{attrs:{"src":require("@/assets/images/logo_03.png"),"alt":"logo"}})]),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"inner clear"},[_c('ul',{staticClass:"bigUl clear fl"},[_c('li',{staticClass:"pc-nav bg"},[_c('a',{attrs:{"title":"首页"},on:{"click":function($event){return _vm.jump('/Home/Index', {

          })}}},[_vm._v("首页 "),_c('p',{staticStyle:{"width":"46px","height":"2px","background":"#1F4C91"}})])]),_c('li',{staticClass:"pc-nav",on:{"mouseleave":function($event){_vm.curr = 0}}},[_c('a',{attrs:{"title":"新媒体"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'qkxmt'
          })},"mousemove":function($event){_vm.curr = 1}}},[_vm._v("新媒体")]),(_vm.curr == 1)?_c('div',{staticClass:"down-menu",staticStyle:{"display":"block"},on:{"mousemove":function($event){_vm.curr = 1}}},[_c('dl',[_c('dt',[_c('a',{attrs:{"title":"学术会议"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2383,
            id: 2375
          })}}},[_vm._v("学术会议")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"视频直播"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2382,
            id: 2375
          })}}},[_vm._v("视频直播")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"知识分享"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2381,
            id: 2375
          })}}},[_vm._v("知识分享")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"学术人生"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2380,
            id: 2375
          })}}},[_vm._v("学术人生")])])])]):_vm._e()]),_c('li',{staticClass:"pc-nav",on:{"mouseleave":function($event){_vm.curr = 0}}},[_c('a',{attrs:{"title":"智能采编"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })},"mousemove":function($event){_vm.curr = 2}}},[_vm._v("智能采编")]),(_vm.curr == 2)?_c('div',{staticClass:"down-menu",staticStyle:{"display":"block"},on:{"mousemove":function($event){_vm.curr = 2}}},[_c('dl',[_c('dt',[_c('a',{attrs:{"title":"作者投稿"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("作者投稿")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"专家审稿"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("专家审稿")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"编辑流程自定义"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("编辑流程自定义")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"学术不端查询"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("学术不端查询")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"参考文献校对"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("参考文献校对")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"过刊三网发布系统"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("过刊三网发布系统")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"APP移动采编系统"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("APP移动采编系统")])])])]):_vm._e()]),_c('li',{staticClass:"pc-nav",on:{"mouseleave":function($event){_vm.curr = 0}}},[_c('a',{attrs:{"title":"价值贡献"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'jzgx'
          })},"mousemove":function($event){_vm.curr = 3}}},[_vm._v("价值贡献")]),(_vm.curr == 3)?_c('div',{staticClass:"down-menu",staticStyle:{"display":"block"},on:{"mousemove":function($event){_vm.curr = 3}}},[_c('dl',[_c('dt',[_c('a',{attrs:{"title":"作者收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            ChannelID: 2385,
            id: 2384,
          })}}},[_vm._v("作者收益")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"专家收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2387,
            id: 2384
          })}}},[_vm._v("专家收益")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"期刊收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2386,
            id: 2384
          })}}},[_vm._v("期刊收益")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"行业收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2388,
            id: 2384
          })}}},[_vm._v("行业收益")])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"社会贡献"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2389,
            id: 2384
          })}}},[_vm._v("社会贡献")])])])]):_vm._e()]),_c('li',{staticClass:"pc-nav"},[_c('a',{attrs:{"title":"解决方案"},on:{"click":function($event){return _vm.jump('/home/list1', {
            Channelid: 2377,
          })}}},[_vm._v("解决方案")])]),_c('li',{staticClass:"pc-nav"},[_c('a',{attrs:{"title":"合作伙伴"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'hzhb'
          })}}},[_vm._v("合作伙伴")])]),_c('li',{staticClass:"pc-nav"},[_c('a',{attrs:{"title":"关于我们"},on:{"click":function($event){return _vm.jump('/html/aboutUs', {
          })}}},[_vm._v("关于我们")])])])])]),_vm._m(0),_vm._m(1)])]),_c('ul',{staticClass:"smallUl"},[_c('li',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'qkxmt'
          })}}},[_vm._v("首页")])]),_c('li',[_vm._m(2),_c('div',{staticClass:"down-menu",staticStyle:{"display":"none"}},[_c('dl',[_c('dt',[_c('a',{attrs:{"title":"学术人生"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2380,
            id: 2375
          })}}},[_vm._v("学术人生"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"知识分享"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2381,
            id: 2375
          })}}},[_vm._v("知识分享"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"视频直播"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2382,
            id: 2375
          })}}},[_vm._v("视频直播"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"title":"学术会议"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2383,
            id: 2375
          })}}},[_vm._v("学术会议"),_c('i',{staticClass:"icon2"})])])])])]),_vm._m(3),_c('li',[_c('a',{attrs:{"title":"解决方案"},on:{"click":function($event){return _vm.jump('/home/list1', {
          })}}},[_vm._v("解决方案")])]),_vm._m(4),_c('li',[_c('a',{attrs:{"title":"关于我们"},on:{"click":function($event){return _vm.jump('/html/aboutUs', {
          })}}},[_vm._v("关于我们")])])])]),_c('router-view'),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"top clear"},[_c('div',{staticClass:"top-box fl clear"},[_vm._m(5),_c('div',{staticClass:"top-m fl clear"},[_c('div',[_c('dl',[_vm._m(6),_c('dd',[_c('a',{attrs:{"title":"学术会议"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2383,
            id: 2375
          })}}},[_vm._v("学术会议")])]),_c('dd',[_c('a',{attrs:{"title":"视频直播"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2382,
            id: 2375
          })}}},[_vm._v("视频直播")])]),_c('dd',[_c('a',{attrs:{"title":"知识分享"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2381,
            id: 2375
          })}}},[_vm._v("知识分享")])]),_c('dd',[_c('a',{attrs:{"title":"学术人生"},on:{"click":function($event){return _vm.jump('/home/list', {
            Channelid: 2380,
            id: 2375
          })}}},[_vm._v("学术人生")])])])]),_c('div',[_c('dl',[_vm._m(7),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("作者投稿系统")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("专家审稿系统")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("编辑流程自定义")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("学术不端查询")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("参考文献校对")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("过刊三网发布系统")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'zncb'
          })}}},[_vm._v("APP移动采编系统")])])])]),_c('div',[_c('dl',[_vm._m(8),_c('dd',[_c('a',{attrs:{"title":"作者收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2385,
            id: 2384
          })}}},[_vm._v("作者收益")])]),_c('dd',[_c('a',{attrs:{"title":"专家收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2387,
            id: 2384
          })}}},[_vm._v("专家收益")])]),_c('dd',[_c('a',{attrs:{"title":"期刊收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2386,
            id: 2384
          })}}},[_vm._v("期刊收益")])]),_c('dd',[_c('a',{attrs:{"title":"行业收益"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2388,
            id: 2384
          })}}},[_vm._v("行业收益")])]),_c('dd',[_c('a',{attrs:{"title":"社会贡献"},on:{"click":function($event){return _vm.jump('/home/show', {
            Channelid: 2389,
            id: 2384
          })}}},[_vm._v("社会贡献")])])])]),_c('div',[_c('dl',[_c('dt',[_c('a',{attrs:{"title":"合作伙伴"},on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'hzhb'
          })}}},[_vm._v("合作伙伴")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'hzhb'
          })}}},[_vm._v("共建单位")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'hzhb'
          })}}},[_vm._v("行业伙伴")])]),_c('dd',[_c('a',{on:{"click":function($event){return _vm.jump('/Home/Index', {
            name: 'hzhb'
          })}}},[_vm._v("领域专家")])])])])])]),_c('div',{staticClass:"top-r fr"})]),_c('div',{staticClass:"bot clear"},[_c('p',{staticClass:"fl"},[_vm._v("Copyright @ 2023 北京博渊星辰网络科技有限公司 All Rights Reserved")]),_c('a',{staticClass:"fr",staticStyle:{"color":"#b7b5b5"},attrs:{"href":"http://www.miitbeian.gov.cn/","target":"_blank"}},[_vm._v("京ICP备"+_vm._s(_vm.name))])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iphone fr clear",staticStyle:{"display":"flex","align-items":"center","margin-bottom":"5px"}},[_c('img',{staticClass:"fl img",staticStyle:{"margin":"0"},attrs:{"src":require("@/assets/images/iphone_03.png"),"alt":"iphone"}}),_c('div',{staticClass:"text fl",staticStyle:{"margin-left":"5px"}},[_c('p',{staticClass:"f25"},[_vm._v("010-63361626")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navList fr"},[_c('span',{staticClass:"span1"}),_vm._v(" "),_c('span',{staticClass:"span2"}),_vm._v(" "),_c('span',{staticClass:"span3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("新媒体"),_c('i',{staticClass:"icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("智能采编"),_c('i',{staticClass:"icon"})]),_c('div',{staticClass:"down-menu",staticStyle:{"display":"none"}},[_c('dl',[_c('dt',[_c('a',{attrs:{"href":"#zncb","title":"作者投稿系统"}},[_vm._v("作者投稿系统"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"#zncb","title":"专家审稿系统"}},[_vm._v("专家审稿系统"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"#zncb","title":"编辑流程自定义"}},[_vm._v("编辑流程自定义"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"#zncb","title":"学术不端查询"}},[_vm._v("学术不端查询"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"#zncb","title":"参考文献校对"}},[_vm._v("参考文献校对"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"#zncb","title":"过刊三网发布系统"}},[_vm._v("过刊三网发布系统"),_c('i',{staticClass:"icon2"})])])]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"#zncb","title":"APP移动采编系统"}},[_vm._v("APP移动采编系统"),_c('i',{staticClass:"icon2"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#hzhb","title":"合作伙伴"}},[_vm._v("合作伙伴")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-l fl"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('a',{staticClass:"logo",attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/images/logo2_03.png"),"alt":"logo"}})])]),_c('p',[_vm._v("咨询电话（工作日9:00-18:00）")]),_c('p',{staticClass:"f33 co-fff"},[_vm._v("010-63361626"),_c('br')]),_c('p',[_c('i',[_c('img',{attrs:{"src":require("@/assets/images/footer-icon_03.png")}})]),_vm._v("boyuanxingchen@163.com")]),_c('p',[_c('i',[_c('img',{attrs:{"src":require("@/assets/images/footer-icon_07.png")}})]),_vm._v("北京市海淀区莲花池东路小马厂6号 华天大厦 824 室")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"#","title":"新媒体"}},[_vm._v("新媒体")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"#","title":"智能采编"}},[_vm._v("智能采编")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"#","title":"价值贡献"}},[_vm._v("价值贡献")])])
}]

export { render, staticRenderFns }