<template>
  <div id="app">
    <div class="header">



      <div class="top" style="display: flex;padding-bottom:2px;">
        <div class="inner clear"
          style="display: flex;align-items: flex-end;width: 100%;max-width: 100%;justify-content: center;">
          <a style="margin-right: 60px;" @click="jump('/Home/Index', {

          })" class="logo fl"><img src="@/assets/images/logo_03.png" alt="logo" /></a>


          <div class="nav">
            <div class="inner clear">
              <ul class="bigUl clear fl">
                <li class="pc-nav bg"><a @click="jump('/Home/Index', {

          })" title="首页">首页
                    <p style="width: 46px;
height: 2px;
background: #1F4C91;">

                    </p>
                  </a></li>
                <li class="pc-nav " @mouseleave="curr = 0">
                  <a @click="jump('/Home/Index', {
            name: 'qkxmt'
          })" title="新媒体" @mousemove="curr = 1">新媒体</a>
                  <div class="down-menu" @mousemove="curr = 1" v-if="curr == 1" style="display:block;">
                    <dl>
                      <dt><a @click="jump('/home/list', {
            Channelid: 2383,
            id: 2375
          })" title="学术会议">学术会议</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/home/list', {
            Channelid: 2382,
            id: 2375
          })" title="视频直播">视频直播</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/home/list', {
            Channelid: 2381,
            id: 2375
          })" title="知识分享">知识分享</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/home/list', {
            Channelid: 2380,
            id: 2375
          })" title="学术人生">学术人生</a></dt>

                    </dl>

                  </div>
                </li>
                <li class="pc-nav " @mouseleave="curr = 0">
                  <a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="智能采编" @mousemove="curr = 2">智能采编</a>
                  <div class="down-menu" @mousemove="curr = 2" v-if="curr == 2" style="display:block;">
                    <dl>
                      <dt><a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="作者投稿">作者投稿</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="专家审稿">专家审稿</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="编辑流程自定义">编辑流程自定义</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="学术不端查询">学术不端查询</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="参考文献校对">参考文献校对</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="过刊三网发布系统">过刊三网发布系统</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/Home/Index', {
            name: 'zncb'
          })" title="APP移动采编系统">APP移动采编系统</a></dt>

                    </dl>
                  </div>
                </li>
                <li class="pc-nav " @mouseleave="curr = 0">
                  <a @click="jump('/Home/Index', {
            name: 'jzgx'
          })" title="价值贡献" @mousemove="curr = 3">价值贡献</a>
                  <div class="down-menu" @mousemove="curr = 3" v-if="curr == 3" style="display:block;">
                    <dl>
                      <dt><a @click="jump('/home/show', {
            ChannelID: 2385,
            id: 2384,
          })" title="作者收益">作者收益</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/home/show', {
            Channelid: 2387,
            id: 2384
          })" title="专家收益">专家收益</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/home/show', {
            Channelid: 2386,
            id: 2384
          })" title="期刊收益">期刊收益</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/home/show', {
            Channelid: 2388,
            id: 2384
          })" title="行业收益">行业收益</a></dt>

                    </dl>
                    <dl>
                      <dt><a @click="jump('/home/show', {
            Channelid: 2389,
            id: 2384
          })" title="社会贡献">社会贡献</a></dt>

                    </dl>


                  </div>
                </li>
                <li class="pc-nav "><a @click="jump('/home/list1', {
            Channelid: 2377,
          })" title="解决方案">解决方案</a></li>
                <li class="pc-nav "><a @click="jump('/Home/Index', {
            name: 'hzhb'
          })" title="合作伙伴">合作伙伴</a></li>
                <li class="pc-nav "><a @click="jump('/html/aboutUs', {
          })" title="关于我们">关于我们</a></li>
              </ul>
            </div>
          </div>


          <div class="iphone fr clear" style="display: flex;align-items: center;margin-bottom: 5px;" >
            <img src="@/assets/images/iphone_03.png" class="fl img" alt="iphone" style="margin:0;" />
            <div class="text fl" style="margin-left:5px;" >
              <!-- <p class="f15">咨询电话:</p> -->
              <p class="f25">010-63361626</p>
            </div>
          </div>
          <div class="navList fr"> <span class="span1"></span> <span class="span2"></span> <span class="span3"></span>
          </div>
        </div>
      </div>

      <!--手机菜单-->
      <ul class="smallUl">
        <li><a @click="jump('/Home/Index', {
            name: 'qkxmt'
          })">首页</a></li>
        <li>
          <a href="javascript:void(0);">新媒体<i class="icon"></i></a>
          <div class="down-menu" style="display:none;">
            <dl>
              <dt><a @click="jump('/home/list', {
            Channelid: 2380,
            id: 2375
          })" title="学术人生">学术人生<i class="icon2"></i></a></dt>

            </dl>
            <dl>
              <dt><a @click="jump('/home/list', {
            Channelid: 2381,
            id: 2375
          })" title="知识分享">知识分享<i class="icon2"></i></a></dt>

            </dl>
            <dl>
              <dt><a @click="jump('/home/list', {
            Channelid: 2382,
            id: 2375
          })" title="视频直播">视频直播<i class="icon2"></i></a></dt>

            </dl>
            <dl>
              <dt><a @click="jump('/home/list', {
            Channelid: 2383,
            id: 2375
          })" title="学术会议">学术会议<i class="icon2"></i></a></dt>

            </dl>
          </div>
        </li>
        <li>
          <a href="javascript:void(0);">智能采编<i class="icon"></i></a>
          <div class="down-menu" style="display:none;">
            <dl>
              <dt><a href="#zncb" title="作者投稿系统">作者投稿系统<i class="icon2"></i></a></dt>
            </dl>
            <dl>
              <dt><a href="#zncb" title="专家审稿系统">专家审稿系统<i class="icon2"></i></a></dt>
            </dl>
            <dl>
              <dt><a href="#zncb" title="编辑流程自定义">编辑流程自定义<i class="icon2"></i></a></dt>
            </dl>
            <dl>
              <dt><a href="#zncb" title="学术不端查询">学术不端查询<i class="icon2"></i></a></dt>
            </dl>
            <dl>
              <dt><a href="#zncb" title="参考文献校对">参考文献校对<i class="icon2"></i></a></dt>
            </dl>
            <dl>
              <dt><a href="#zncb" title="过刊三网发布系统">过刊三网发布系统<i class="icon2"></i></a></dt>
            </dl>
            <dl>
              <dt><a href="#zncb" title="APP移动采编系统">APP移动采编系统<i class="icon2"></i></a></dt>
            </dl>
          </div>
        </li>

        <li><a @click="jump('/home/list1', {
          })" title="解决方案">解决方案</a></li>
        <li><a href="#hzhb" title="合作伙伴">合作伙伴</a></li>
        <li><a @click="jump('/html/aboutUs', {
          })" title="关于我们">关于我们</a></li>
      </ul>
    </div>
    <router-view />

    <div class="footer">
      <div class="inner">
        <div class="top clear">
          <div class="top-box fl clear">
            <div class="top-l fl">
              <div style="text-align:center;">
                <a href="#" class="logo"><img src="@/assets/images/logo2_03.png" alt="logo" /></a>
              </div>
              <p>咨询电话（工作日9:00-18:00）</p>
              <p class="f33 co-fff">010-63361626<br /></p>
              <p><i><img src="@/assets/images/footer-icon_03.png" /></i>boyuanxingchen@163.com</p>
              <p><i><img src="@/assets/images/footer-icon_07.png" /></i>北京市海淀区莲花池东路小马厂6号
                华天大厦 824 室</p>
            </div>
            <div class="top-m fl clear">
              <div>
                <dl>

                  <dt><a href="#" title="新媒体">新媒体</a></dt>
                  <dd><a @click="jump('/home/list', {
            Channelid: 2383,
            id: 2375
          })" title="学术会议">学术会议</a></dd>
                  <dd><a @click="jump('/home/list', {
            Channelid: 2382,
            id: 2375
          })" title="视频直播">视频直播</a></dd>
                  <dd><a @click="jump('/home/list', {
            Channelid: 2381,
            id: 2375
          })" title="知识分享">知识分享</a></dd>
                  <dd><a @click="jump('/home/list', {
            Channelid: 2380,
            id: 2375
          })" title="学术人生">学术人生</a></dd>
                </dl>
              </div>
              <div>
                <dl>
                  <dt><a href="#" title="智能采编">智能采编</a></dt>
                  <dd><a @click="jump('/Home/Index', {
            name: 'zncb'
          })">作者投稿系统</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'zncb'
          })">专家审稿系统</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'zncb'
          })">编辑流程自定义</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'zncb'
          })">学术不端查询</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'zncb'
          })">参考文献校对</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'zncb'
          })">过刊三网发布系统</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'zncb'
          })">APP移动采编系统</a></dd>
                </dl>
              </div>
              <div>
                <dl>
                  <dt><a href="#" title="价值贡献">价值贡献</a></dt>
                  <dd><a @click="jump('/home/show', {
            Channelid: 2385,
            id: 2384
          })" title="作者收益">作者收益</a></dd>
                  <dd><a @click="jump('/home/show', {
            Channelid: 2387,
            id: 2384
          })" title="专家收益">专家收益</a></dd>
                  <dd><a @click="jump('/home/show', {
            Channelid: 2386,
            id: 2384
          })" title="期刊收益">期刊收益</a></dd>
                  <dd><a @click="jump('/home/show', {
            Channelid: 2388,
            id: 2384
          })" title="行业收益">行业收益</a></dd>
                  <dd><a @click="jump('/home/show', {
            Channelid: 2389,
            id: 2384
          })" title="社会贡献">社会贡献</a></dd>
                </dl>
              </div>
              <div>
                <dl>
                  <dt><a @click="jump('/Home/Index', {
            name: 'hzhb'
          })" title="合作伙伴">合作伙伴</a></dt>
                  <dd><a @click="jump('/Home/Index', {
            name: 'hzhb'
          })">共建单位</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'hzhb'
          })">行业伙伴</a></dd>
                  <dd><a @click="jump('/Home/Index', {
            name: 'hzhb'
          })">领域专家</a></dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="top-r fr">
            <!-- <img src="@/assets/images/er_03.jpg" alt="二维码" />
            <p>微信公众号</p> -->
          </div>
        </div>
        <div class="bot clear">
          <p class="fl">Copyright @ 2023 北京博渊星辰网络科技有限公司 All Rights Reserved</p>
          <a href="http://www.miitbeian.gov.cn/" class="fr" target="_blank" style="color: #b7b5b5;">京ICP备{{ name }}</a>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curr: 0,
      name: ''
    }
  },
  methods: {
    jump(path, query) {
      this.$router.push({
        path,
        query
      })
      if (query.name) {
        this.$nextTick(() => {
          // 使用window.location.hash来定位锚点
          window.location.hash = '#' + query.name;
        });
      }

    }
  },
  created() {
    let name = location.hostname
    if (name == 'boyuanxc.cn') {
      this.name = '2024042010号-1'
    } else if (name == 'boyuancb.com') {
      this.name = '2024042010号-2'
    } else {
      this.name = '2024042010号'
    }

  }
}
</script>
<style lang="less" scoped>
a {
  cursor: pointer;
}
</style>
